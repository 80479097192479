<template>
    <div class="body">
        <div class="container">
            <div class="title">{{ $t('My Profile') }}</div>
            <div class="row mx-0">
                <div class="col-md-2 mt-4">
                    <div class="profile-picture ms-md-0 me-md-auto mx-auto">
                        <img :src="profilePictureUrl" alt="profile" height="100" width="100" loading="lazy" draggable="false">
                    </div>
                    <div class="mt-4">
                        <input type="file" ref="profilePictureInput" style="display: none;" @change="handleProfilePictureChange">
                        <button @click="openProfilePictureInput">{{ $t('Change Profile Picture') }}</button>
                    </div>                    
                </div>
                <div class="col-md-10 my-4">
                    <div class="form-container">
                        <form @submit.prevent="updateProfile">

                            <div class="row">

                                <div class="col-md-6">
                                    <div class="input-container">
                                        <label for="name">{{ $t('Name') }}</label>
                                        <input type="text" id="name" name="name" v-model="user.name">
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="input-container">
                                        <label for="password">{{ $t('Password') }}</label>
                                        <input type="password"  :placeholder="$t('Password')" name="password" v-model="user.password">
                                        <div class="show-password"><i class="bi bi-eye-fill"></i></div>
                                    </div>
                                </div>
                                
                                <div class="col-md-6">
                                    <div class="input-container">
                                        <label for="phone">{{ $t('Contact Number') }}</label>
                                        <input type="tel" id="phone" name="phone" v-model="user.phone">
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="input-container">
                                        <label for="email">{{ $t('Email') }}</label>
                                        <input type="email" id="email" name="email" v-model="user.email" disabled>
                                    </div>
                                </div>

                                <div class="col-12 my-4">
                                    <div class="md:w-1/4 me-auto">
                                        <Button type="submit" :title="$t('Save Changes')" ButtonStyle="color-fill" />
                                    </div>
                                </div>
                                <LoadingAnimation :isLoading="isLoading" />
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>     
    </div>
</template>

<script>

import Button from '@/components/button/button.component.vue'
import axios from 'axios';
import defaultProfile from '@/assets/images/misc/profile-default.jpg'
import LoadingAnimation from '@/components/queue-loading/queue-loading.component.vue';

export default {
    components: {
        Button,  
        LoadingAnimation
    },
    data() {
        return {
           user: {},
           url: "",
           profilePictureUrl: defaultProfile,
           isLoading: false,
        };
    },
    methods: {
        updateProfile() {
            this.isLoading = true;
            this.url = this.HostUrl + "update-profile"
            console.log(this.user)
            axios.post(this.url, {
                user_id: this.UserID,
                name: this.user.name,
                phone:this.user.phone,
                password: this.user.password

            })
            .then(response => {
                console.log(response.data);
                alert(this.$t('Your profile updated successfully'));
                // show success message to user
            })
            .catch(error => {
                console.log(error);
                alert(this.$t('Profile update fail!'));
                // show error message to user
            })
            .finally(() => {
                this.isLoading = false; // Hide the loader
            });
        },
        getProfile(){
            this.url = this.HostUrl + "get-profile"
            axios.post(this.url,{
                user_id: this.UserID
            })
            .then(response => {
                this.user = response.data;
                if(response.data.profile_picture != "no image"){
                    this.profilePictureUrl = response.data.profile_picture;
                }
                else{
                    this.profilePictureUrl = defaultProfile;
                }
                
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {
                this.isLoading = false; // Hide the loader
            });
        },
        openProfilePictureInput() {
            this.$refs.profilePictureInput.click();
        },
        handleProfilePictureChange() {
            this.url = this.HostUrl + "upload-profile-picture"
            const formData = new FormData();
            formData.append('profile_picture', this.$refs.profilePictureInput.files[0]);
            formData.append('user_id', this.UserID);
            console.log(formData)
            axios.post(this.url,formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                console.log(response.data);
                alert(this.$t('Profile picture upload successfully'));
                // show success message to user
                this.getProfile();
            })
            .catch(error => {
                alert(this.$t('Profile picture upload failed'));
                // show error message to user
            });
        }

    },
        

    
    created() {
        this.isLoading = true;
        this.getProfile();
    },

    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
    }
};
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './profile.styles.scss';
</style>