<template>
    <div class="tnc-content">
        <div class="title">{{DATA.title}}</div>        
        <p v-for="(content, index) in DATA.content" :key="index">
            {{content}}
        </p>
    </div>  
</template>

<script>

export default {
  name: 'TncContent',
  props: {
    DATA: Object,
  },
  components: {},
}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './tnc-content.styles.scss';
</style>