<template>
    <Navbar />
    <div class="body">        
        <div class="container">
            <div class="login-container">
                <div class="login-content">
                    <div class="title mb-4">FORGET PASSWORD</div>
                    <div class="input-container md:w-3/4 mx-auto">
                        <input type="email" placeholder="Email" id="forget-password" name="forget-password" v-model="email">
                    </div>
                    <div class="mt-12 mb-6 login-button">                        
                        <Button type="submit" title="Send" @click="resetPassword" />
                    </div>
                    <LoadingAnimation :isLoading="isLoading" />
                </div>
            </div>
        </div>
        
    </div>
    <Footer />
</template>

<script>

import Navbar from '@/components/navbar/navbar.component.vue'
import Footer from '@/components/footer/footer.component.vue'
import Button from '@/components/button/button.component.vue'
import axios from 'axios';
import LoadingAnimation from '@/components/queue-loading/queue-loading.component.vue';
export default {
    components: {
        Navbar,       
        Footer,       
        Button, 
        LoadingAnimation      
    },
    data() {
        return {
           email:"",
           url: "",
           isLoading: false,
        };
    },
    methods: {
        resetPassword() {
            if (!this.email) {
                alert(this.$t('Please enter your email'));
                return;
            }
            const data = {
                email: this.email,
            };
            this.isLoading = true;
            axios
                .post(this.url, data)
                .then((response) => {
                    console.log(response)
                    alert(response.data.message);
                    this.$router.push("/login");
                })
                .catch((error) => {
                    console.log(error)
                    
                })
                .finally(() => {
                    this.isLoading = false; // Hide the loader
                });
        },
    },
    created() {
        this.url = this.HostUrl + "reset-password"
    },
    computed: {
        HostUrl() {
            return this.$store.state.host_url;
        },
    }
};
</script>
<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './forget-password.styles.scss';
</style>