import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import cn from './locales/cn.json'
import jp from './locales/jp.json'
import './assets/css/main.css'
import { VueSplide } from '@splidejs/vue-splide'
import { createHead } from '@vueuse/head'
const messages = {
  en,
  cn,
  jp
  
}

const stripe = {
  pk: "pk_test_51N0YKxIDUewTemhJx93p2lek7dDI1P0tIH9QOc9LsctdBDmpLoUbzj8RiMfgmRXnc9MfI422NS8JF8JYi1Vr0JWO002xbfIk5r",
  stripeAccount: undefined,
  apiVersion: "2020-08-27",
  locale: "en",
};

const i18n = createI18n({
  legacy: false,
  locale: store.state.language,
  messages
})

const head = createHead();

createApp(App)
  .use(store)
  .use(i18n)
  .use(router)
  .use(VueSplide)
  .use(head)
  .mount('#app')
