<template>
    <div class="navbar-section ">
        <div class="container">
            <a href="/" class="logo">
              <img src="@/assets/images/logo/logo.png" alt="logo" height="40" width="182">
            </a>
            <div class="icon-container">
                <div class="close-icon"><i class="fas fa-times"></i></div>
                <a class="icon close-button mt-8 mt-md-0" @click="make_booking" v-if="IsLogin">{{ $t('Make a booking') }}</a> 
                <a class="icon close-button" @click="manage_booking" v-if="IsLogin">{{ $t('Manage my booking') }}</a> 
                <a class="icon close-button" @click="profile" v-if="IsLogin">{{ $t('Profile') }}</a> 
                <a class="icon close-button" @click="logout" v-if="IsLogin">{{ $t('Logout') }}</a> 
                <a href="/login" class="icon" v-else>{{ $t('Login') }}</a>    
                <div class="icon language ">
                    <i class="bi bi-translate"></i>
                    <div class="dropdown">
                        <div class="dropdown-item close-button" @click="switchLanguage('en')">English</div>
                        <div class="dropdown-item close-button" @click="switchLanguage('cn')">中文</div>
                        <div class="dropdown-item close-button" @click="switchLanguage('jp')">日文</div>
                    </div>                 
                </div> 
                <div class="icon language mobile close-button">
                  <div class="display">                      
                    <i class="bi bi-translate"></i>
                    <div class="display-text">English</div>
                  </div>
                </div>
            </div>            
            <div class="menu-icon">
              <i class="fas fa-bars"></i>              
            </div>
        </div>
        <div class="mobile-language-container">
            <div class="dropdown mobile">
                <div class="dropdown-item close-button" @click="switchLanguage('en')">English</div>
                <div class="dropdown-item close-button" @click="switchLanguage('cn')">中文</div>
                <div class="dropdown-item close-button" @click="switchLanguage('jp')">日文</div>
            </div>   
        </div>
    </div>
</template>

<script>

import CustomModal from '@/components/custom-modal/custom-modal.component.vue'
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { navLanguage, navMobileMenu } from './navbar.script.js'

export default {
  name: 'Navbar',
  props: {},
  components: {
    CustomModal,
  },
  methods: {
    switchLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch("setLanguage", lang);
    },
    logout(){
      this.$store.commit("IsLogin", false);
      this.$store.commit("Username", "");
      this.$router.push("/login");
    },
    make_booking(){
      this.$router.push("/booking");
    },
    manage_booking(){
      this.$router.push("/manage-bookings");
    },
    profile(){
      this.$router.push("/profile");
    }
  },
  computed: {
    IsLogin() {
        return this.$store.state.is_login;
    },
  },
  setup() {
    const store = useStore();
    const { locale } = useI18n();
    const selectedLanguage = computed({
      get: () => locale.value,
      set: (value) => store.dispatch('setLanguage', value), 
    });

    return {
      selectedLanguage,
    };
  },
  mounted() {
    navLanguage();
    navMobileMenu();    
  }
}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './navbar.styles.scss';
</style>