import { createRouter, createWebHistory } from 'vue-router'

import store from '../store' 

import HomeView from '@/views/home/HomeView.vue'
import BookingView from '@/views/booking/Booking'
import LoginView from '@/views/login/LoginView'
import ProfileView from '@/views/profile/Profile'
import RegisterView from '@/views/register/Register'
import ManageBookings from '@/views/manage-bookings/ManageBookings'
import ForgetPassword from '@/views/forget-password/ForgetPassword'
import PaymentView from '@/views/payment/PaymentView'
import ChatRoom from '@/views/chatroom/ChatRoom'
import YesGuide from '@/views/guide/YesGuide'
import NoGuide from '@/views/guide/NoGuide'
import AboutUs from '@/views/about/AboutUs'
const routes = [

  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/guide-yes',
    name: 'YesGuide',
    component: YesGuide,
  },
  {
    path: '/guide-no',
    name: 'NoGuide',
    component: NoGuide,
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/booking',
    name: 'Booking',
    component: BookingView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/chat/:booking_id',
    name: 'ChatRoom',
    component: ChatRoom,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/manage-bookings',
    name: 'ManageBookings',
    component: ManageBookings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/payment',
    name: 'Payment',
    component: PaymentView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.is_login) {
      next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})
export default router