<template>
    <span class="modal-section " :class="{active: isToggle}">
        <div class="modal-box">
            <div class="close-icon close-button"><i class="bi bi-x-lg"></i></div>
            <slot></slot>
        </div>
    </span>
</template>

<script>

export default {
  name: 'CustomModal',
  props: {
    isToggle: Boolean,
  },
  components: {},
  data(){
    return{
    }
  },
  methods: {
    closeModal() {
      this.$emit('toggleModal')
      document.body.style.overflow = "";
    },
    setupModal() {
        const modalSections = document.querySelectorAll('.modal-section');
        if (!modalSections.length) throw new Error('Cannot find class "modal-section"');

        modalSections.forEach(modalSection => {
            const modalBox = modalSection.querySelector('.modal-box');
            const closeButtons = modalSection.querySelectorAll('.close-button');

            modalSection.addEventListener('click',(e)=>{
                if (modalBox.contains(e.target)) return;
                modalSection.classList.remove('active');
                this.closeModal();
            })

            if (closeButtons.length) {
                closeButtons.forEach(button => {
                    button.addEventListener('click',()=>{
                        modalSection.classList.remove('active');
                        this.closeModal();
                    })                    
                });
            }
        });
    },

  },
  mounted(){
    this.setupModal();
  }
}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './custom-modal.styles.scss';
</style>