<template>
    <div class="body">
        <div class="container">
            <div class="about-us-container">
            <div class="title">{{ $t('About Us') }}</div>   
            <div>
              <p v-if="$i18n.locale=='en'">
                Welcome to NarabuQ, your ultimate travel companion for discovering the best of Tokyo! Our company specializes in providing unparalleled proxy queuing services and personalized shopping recommendations for tourists exploring the vibrant and bustling city of Tokyo, Japan.
              </p>
              <p v-if="$i18n.locale=='cn'">
                欢迎来到NarabuQ,您在探索充满活力和繁华的东京城市时的最佳旅行伙伴!我们的公司专业提供无与伦比的代理排队服务和针对游客的个性化购物推荐。
              </p>
              <p v-if="$i18n.locale=='jp'">
                NarabuQへようこそ、東京の活気あふれる繁華街を探索する旅行者にとっての最高のパートナーです！当社は、類を見ない代行待ち行列サービスと、東京の観光客向けのパーソナライズされたショッピング推奨を提供することを専門としています。
              </p>
            </div>
            <div class="row content-row">
                <div class="col-md-4">
                  <video autoplay loop muted>
                 
                    <source src="@/assets/videos/about_us/about_us_1.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                  </video>
                </div>
                <div class="col-md-8">
                    <div v-if="$i18n.locale=='en'">
                      <p>
                        Our mission is to enhance your travel experience by taking the hassle out of waiting in long lines for buying goods or souvenirs in popular attractions, while also guiding you towards the perfect souvenirs and unique local products that capture the essence of Tokyo.
                      </p>
                      <p>
                        Founded by a team of passionate Tokyo enthusiasts and seasoned travelers, we possess an extensive understanding of the city's cultural nuances, shopping trends, and time-saving hacks. We believe that every moment spent in Tokyo should be cherished, and our services are designed to help you maximize your time in this incredible city.
                      </p>
                    </div>
                    <div v-if="$i18n.locale=='cn'">
                      <p>
                        我们的使命是通过消除在热门景点购买商品或纪念品的长队等待之苦，增强您的旅行体验，同时引导您寻找完美的纪念品和独特的当地产品，捕捉东京的精髓。
                      </p>
                      <p>
                        我们的团队由热爱东京的热情爱好者和经验丰富的旅行者组成，我们对这个城市的文化细微差异，购物趋势，和节省时间的技巧有着广泛的理解。我们相信在东京度过的每一刻都应该被珍视，我们的服务旨在帮助您最大化在这个令人难以置信的城市中的时间。
                      </p>
                    </div>
                    <div v-if="$i18n.locale=='jp'">
                      <p>
                        私たちのミッションは、人気の観光地での商品やお土産の購入のための長い行列を取り除き、旅行体験を強化すること、そして東京の魂を捉える完璧なお土産やユニークな地元の製品へとあなたを導くことです。
                      </p>
                      <p>
                        東京愛好家と経験豊富な旅行者のチームによって設立された当社は、この都市の文化的なニュアンス、ショッピングトレンド、そして時間節約の秘訣について深い理解を持っています。東京で過ごすすべての瞬間は大切にすべきだと私たちは信じています、そして私たちのサービスはあなたがこの信じられないほどの都市での時間を最大限に活用するのを助けるためにデザインされています。
                      </p>
                    </div>
                </div>
            </div>

            <div class="row content-row">
                <div class="col-md-4">

                  <video autoplay loop muted>
                 
                    <source src="@/assets/videos/about_us/about_us_2.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                  </video>
                </div>
                <div class="col-md-8">
                    <div v-if="$i18n.locale=='en'">
                      <p>
                        At NarabuQ, we offer:
                        <ul style="">
                          <li>Proxy Queuing Service: Our dedicated team will stand in line for you to purchase any goods or souvenirs in popular attractions or events, allowing you to focus on exploring Tokyo and making unforgettable memories without worrying about long queues.</li>
                          <li>Personalized Shopping Recommendations: Based on your preferences, interests, and budget, our knowledgeable staff will guide you to the best shops and markets, showcasing hidden gems and exclusive finds that truly represent the spirit of Tokyo.</li>
                          <li>Expert Advice: As local insiders, our team is always available to provide you with invaluable tips and suggestions on how to make the most of your trip, whether it's navigating the city's public transport system or locating the best street food vendors.</li>
                        </ul>
                      </p>
                     
                    </div>
                    <div v-if="$i18n.locale=='cn'">
                      <p>
                        在NarabuQ,我们提供：
                        <ul style="">
                          <li>代理排队服务：我们专门的团队会替你在热门景点或活动中排队购买任何商品或纪念品，让你专注于探索东京，创造难忘的记忆，无需担心长队。</li>
                          <li>个性化购物推荐：根据您的喜好，兴趣和预算，我们的知识丰富的员工会引导您去最好的商店和市场，展示隐藏的宝石和独家发现，真正代表东京的精神。</li>
                          <li>专家建议：作为本地的内行人，我们的团队随时提供有价值的提示和建议，帮助您充分利用您的旅行，无论是导航城市的公共交通系统还是寻找最好的街头食品供应商。</li>
                        </ul>
                      </p>
                    </div>
                    <div v-if="$i18n.locale=='jp'">

                      <p>
                        NarabuQでは、次のようなサービスを提供しています：
                        <ul style="">
                          <li>代行待ち行列サービス：私たちの専門チームが人気の観光地やイベントで商品やお土産を購入するためにあなたの代わりに並びます。これにより、東京を探索し、忘れられない思い出を作ることに集中できます。</li>
                          <li>人ひとりにあったショッピングの推奨 ：あなたの好み、興味、予算に基づき、私たちの知識豊富なスタッフが最高のショップや市場へとあなたを案内します。東京の魂を本当に代表する隠れた宝石や独占的な発見を披露します。</li>
                          <li>専門家のアドバイス：地元のインサイダーとして、私たちのチームはいつでもあなたに都市の公共交通システムのナビゲーションや最高のストリートフードベンダーの位置を見つける方法など、旅行を最大限に活用するための貴重なヒントや提案を提供します。</li>
                        </ul>
                      </p>
                    </div>
                </div>
            </div>
            <div>
              <p v-if="$i18n.locale=='en'">
                Your time in Tokyo is precious, and NarabuQ is committed to ensuring you to make the most of it. Discover the magic of Tokyo with us, and let our unparalleled services transform your trip into the adventure of a lifetime.
              </p>
              <p v-if="$i18n.locale=='cn'">
                你在东京的时间宝贵，NarabuQ致力于确保你充分利用它。和我们一起发现东京的魔力，让我们无与伦比的服务将您的旅程变成一生的冒险。
              </p>
              <p v-if="$i18n.locale=='jp'">
                あなたの東京での時間は貴重であり、NarabuQはあなたがそれを最大限に活用することを確実にします。私たちと一緒に東京の魔法を発見し、私たちの類を見ないサービスがあなたの旅行を一生の冒険に変えるのを見てください。
              </p>
            </div>
          </div>
        </div>
   </div>
</template>

<script>
// @ is an alias to /src



export default {
  name: 'AboutUs',
  components: {
 
  },
  data() {
    return{
    
    }
  }
}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './AboutUs.styles.scss';
</style>
  