<template>  
  <div class="body">
    <div class="container">
      <div class="row">

        <div class="col-md-4">
          <div class="about-image">
            <img src="@/assets/images/misc/queue.png" width="1000" height="460" loading="lazy" draggable="false">
          </div>
        </div>
        <div class="col-md-8">
          <div class="about-us-container">
            <div class="title">{{ $t('About Us') }}</div>   
            <div v-if="$i18n.locale=='en'">
              <p class="content">
                Welcome to NarabuQ, your ultimate travel companion for discovering the best of Tokyo! Our company specializes in providing unparalleled proxy queuing services and personalized shopping recommendations for tourists exploring the vibrant and bustling city of Tokyo, Japan. <a href="/about-us"  style="color:orange">More Information ...</a>
              </p>
              
            </div> 
            <div v-if="$i18n.locale=='cn'">
              <p class="content">
                欢迎来到NarabuQ,您在探索充满活力和繁华的东京城市时的最佳旅行伙伴!我们的公司专业提供无与伦比的代理排队服务和针对游客的个性化购物推荐。<a href="/about-us"  style="color:orange">更多资料 ...</a>
              </p>
            </div>        
            <div v-if="$i18n.locale=='jp'">
              <p class="content">
                  NarabuQへようこそ、東京の活気あふれる繁華街を探索する旅行者にとっての最高のパートナーです！当社は、類を見ない代行待ち行列サービスと、東京の観光客向けのパーソナライズされたショッピング推奨を提供することを専門としています。<a href="/about-us" style="color:orange">もっと情報 ...</a>
              </p>
            </div>  
          </div>
        </div>

        <div class="col-12 my-8">
          <HomeImageSlider />
        </div>
      </div>

      <div class="row my-4">
        <div class="col-12 mb-4 text-center">
          <div class="title">{{ $t('Guide') }}</div>
          <div>{{ $t('Decided what to buy?') }}</div>
        </div>       
        <div class="col-md-6 home-small-card">
            <HomeSmallCard>
              <a href="/guide-yes">
                <div class="card-body">                
                  <div class="card-title mb-2">{{ $t('Yes') }}</div>
                  <div class="card-content"></div>
                </div>
                <div class="card-image">
                    <Splide :options="splideYes">
                    
                      <SplideSlide v-for="(video, index) in yes_videos" :key="index">
                        <video autoplay loop muted>
                 
                          <source :src="video.video" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                      </SplideSlide>
                     
                    </Splide>                
                </div>    
              </a>          
            </HomeSmallCard>
        </div>
        <div class="col-md-6 home-small-card">
            <a href="/guide-no">
              <HomeSmallCard>              
                <div class="card-body">                
                  <div class="card-title mb-2">{{ $t('No') }}</div>
                  <div class="card-content"></div>
                </div>
                <div class="card-image">
                    <Splide :options="splideYes">
                      <SplideSlide v-for="(video, index) in no_videos" :key="index">
                        <video autoplay loop muted>
                 
                          <source :src="video.video" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                      </SplideSlide>
                    </Splide>                
                </div>  
              </HomeSmallCard>
            </a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Button from '@/components/button/button.component.vue'
import HomeImageSlider from '@/components/home-image-slider/home-image-slider.component.vue'
import HomeSmallCard from '@/components/home-small-card/home-small-card.component.vue'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide'


import video_yes_1 from "@/assets/videos/yes_guide/NarabuQ.mp4";
import video_yes_2 from "@/assets/videos/yes_guide/NarabuQ_1.mp4";
import video_yes_3 from "@/assets/videos/yes_guide/NarabuQ_2.mp4";
import video_yes_4 from "@/assets/videos/yes_guide/NarabuQ_4.mp4";

import video_no_1 from "@/assets/videos/no_guide/NarabuQ_1.mp4";
import video_no_2 from "@/assets/videos/no_guide/NarabuQ_3.mp4";
import video_no_3 from "@/assets/videos/no_guide/NarabuQ_4.mp4";
import video_no_4 from "@/assets/videos/no_guide/NarabuQ_6.mp4";
import { useI18n } from 'vue-i18n'
export default {
  name: 'HomeView',
  components: {
    Button,
    HomeImageSlider,
    HomeSmallCard,
    Splide, 
    SplideSlide, 
    SplideTrack,
  },
  setup() {
    const { t } = useI18n()
    return {
      metaInfo() {
          return {
          title: t('Buy and Queue for Your Favorites with NarabuQ'), // use vue-i18n
          meta: [
              // set meta description
              { name: 'description', content: t('Discover a new way to shop with NarabuQ. Queue and purchase your favorite items from around the world with ease. Join NarabuQ and transform your shopping experience.') },
              // set meta keywords
              { name: 'keywords', content: t('buy, queue, shop, NarabuQ') }
          ]
          }
      }
    }
  },

  data() {
    return{
      splideYes: {
          rewind: true,
          autoplay: true,
          interval: 3000,
          arrows: false,
      },
      yes_videos: [
        {
          video: video_yes_1,
        },
        {
          video: video_yes_2,
        },
        {
          video: video_yes_3,
        },
        {
          video: video_yes_4,
        },
      ],
      no_videos: [
   
        {
          video:video_no_1,
        },
        {
          video:video_no_2,
        },
        {
          video:video_no_3,
        },
        {
          video:video_no_4,
        },
        
      ]

    }
  }
}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './HomeView.styles.scss';
</style>