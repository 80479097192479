<template>
    <button class="button-container" :class="ButtonStyle" >
        {{title}}
    </button>
</template>

<script>

export default {
  name: 'Button',
  props: {
    title: String,
    ButtonStyle: String,
  },
  components: {}
}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './button.styles.scss';
</style>