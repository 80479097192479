<template>
    <div class="body">
        <div class="container">
            <button type="button" class="back-button" @click="back_to_manage_booking"><i class="fas fa-caret-left" @click="back_to_manage_booking"></i></button>
            <div class="chat-container" ref="chatRef" @scroll="onScroll">
              <div v-for="(value, key) in messages" :key="key">
                <div class="chat-time">{{ key }}</div>
                <div class="chat-box" v-for="message in value" :key="message.id">
                    <div class="icon-box user-icon" v-if="message.user_ID">
                      <img :src="user_profile_pic" alt="user" draggable="false" width="40" height="40">
                    </div> 
                    <div class="icon-box qers-icon" v-else>
                      <img :src="qers_profile_pic" alt="qers" draggable="false" width="40" height="40">
                    </div>
                    <div class="message-box">
                      <div>{{ message.message }}</div>
                      <small>{{ formatDate(message.created_at) }}</small>
                    </div>
                </div>
              </div>
            </div>
            <form class="send-container" @submit.prevent="sendMessage">
              <input v-model="newMessage" placeholder="Type your message" />
              <button type="submit">Send</button>
            </form>
        </div>        
    </div>
</template>
  
<script>
  import axios from "axios";
  
  export default {
    props: ['booking_id'],
    data() {
      return {
        messages: [],
        newMessage: "",
        url: "",
        chatUser:"",
        currentPage: 1,
        isLoading: false,
        isPageFirstLoad: true,
        qers_profile_pic: "",
        user_profile_pic: ""
      };
    },
    methods: {
      async fetchMessages() {
        this.url = this.HostUrl + 'messages?booking_id=' + this.$route.params.booking_id;
        const response = await axios.get(this.url);
        this.messages = response.data;

      },
      async sendMessage() {
        this.url = this.HostUrl + 'messages';
        if (this.newMessage.trim()) {
          const message = {
            user_ID: this.UserID,
            qers_ID: null,
            booking_ID: this.$route.params.booking_id,
            message: this.newMessage,
          };
          await axios.post(this.url, message);
          this.newMessage = "";
          this.fetchMessages();
        }
      },
      formatDate(timestamp) {
        const date = new Date(timestamp);
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const period = hours >= 12 ? 'pm' : 'am';
        
        // Convert to 12-hour format
        hours = hours % 12;
        // the hour '0' should be '12'
        hours = hours ? hours : 12;
        
        return `${hours}:${minutes} ${period}`;
      },
      back_to_manage_booking(){
        this.$router.push("/manage-bookings");
      },
      async getChatUserID(){
        this.url = this.HostUrl + 'get-chat-user?booking_id=' + this.$route.params.booking_id;
        await axios.get(this.url)
          .then(response => {
            this.chatUser = response.data
            if(parseInt(this.chatUser) !== parseInt(this.UserID)){
              alert(this.$t('This chat is not belonging to you.'))
              this.$router.push("/manage-bookings");
            }
            else{
              this.fetchMessages();   
              
            }
          })
          .catch(error => {
              console.log(error);
          });
        const response = axios.get(this.url);
        this.chatUser = response.data;
      
      },
      async getChatProfilePics(){
        this.url = this.HostUrl + 'get-chat-profile-pic?booking_id=' + this.$route.params.booking_id;
        await axios.get(this.url)
          .then(response => {
    
            this.user_profile_pic = response.data.user_profile_pic == 'no image' ?  require('@/assets/images/icons/user.png') : response.data.user_profile_pic;
            this.qers_profile_pic = response.data.qers_profile_pic == 'no image' ?  require('@/assets/images/icons/qers.png'): response.data.qers_profile_pic;
          })
          .catch(error => {
              console.log(error);
          });

      },
      async loadMore() {
        console.log(this.currentPage)
        if (this.isLoading) return;

        this.isLoading = true;
        this.url = this.HostUrl + 'messages?booking_id=' + this.$route.params.booking_id + '&current_page=' + this.currentPage;
        const response = await axios.get(this.url);
        this.isLoading = false;
  
        var newMessages = response.data;

        if (Object.keys(newMessages).length) {
          // append new messages to the existing messages array
          this.messages = {...this.messages, ...newMessages};
          this.currentPage += 1;
        }
        console.log(this.currentPage)
        console.log(this.messages)
        
      },
      isDateChanged(index) {
        if (index === 0) {
          return true;
        }

        const currentDate = new Date(this.messages[index].created_at).getDate();
        const prevDate = new Date(this.messages[index - 1].created_at).getDate();

        return currentDate !== prevDate;
      },
      onScroll() {
        const element = this.$refs.chatRef;
        if (element.scrollTop === 0) {
          this.loadMore();
        }
      }
    },
    mounted() {
      this.fetchMessages();
      setInterval(this.fetchMessages, 5000); // Poll every 5 seconds  
    },
    unmounted(){
        
    },
    created() {
      this.getChatUserID();
      this.getChatProfilePics();
    },
    computed: {
      HostUrl() {
        return this.$store.state.host_url;
      },
      Username() {
        return this.$store.state.username;
      },
      UserID() {
        return this.$store.state.user_id;
      },

    }, 
    updated() {
      if (this.isPageFirstLoad) {
        const chatRefDiv = this.$refs.chatRef;
        chatRefDiv.scrollTop = chatRefDiv.scrollHeight;
        this.isPageFirstLoad = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './chat-room.styles.scss';
</style>
  