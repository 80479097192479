<template>
  <div class="body">
     <div class="container">
        <div class="title">{{ $t('Step-by-Step Guide to Place an Order for Personalized Souvenir Suggestions and Purchasing Service') }}</div>
         <div class="mb-8">
            {{ $t('Placing an order for our proxy queuing service to buy souvenirs is a breeze with NarabuQ.') }}{{ $t('Follow these easy steps to have our team save your time and effort while shopping for the perfect souvenirs:') }}
         </div>
           <div class="row">
            <div class="col-12 guide-col" v-for="(step, index) in steps" :key="index">
              <div class="image-container">
                  <video autoplay loop muted>               
                     <source :src="step.video" type="video/mp4">
                        Your browser does not support the video tag.
                  </video>                  
               </div>
              <div class="content-container">
                 <div class="step-container">
                    <div class="step-title">
                        <strong>{{ $t('Step') }} {{ index + 1 }}:</strong> 
                        <span v-if="$i18n.locale=='en'">{{ step.title_en }}</span>
                        <span v-if="$i18n.locale=='cn'">{{ step.title_cn }}</span>
                        <span v-if="$i18n.locale=='jp'">{{ step.title_jp }}</span>
                     </div>
                     <div class="step-description">
                        <span v-if="$i18n.locale=='en'">{{ step.description_en }}</span>
                        <span v-if="$i18n.locale=='cn'">{{ step.description_cn }}</span>
                        <span v-if="$i18n.locale=='jp'">{{ step.description_jp }}</span>
                    </div>
                 </div>
              </div>
            </div>              
           </div>
           <div v-if="index < steps.length - 1" class="arrow-container text-center">
              <i class="bi bi-arrow-down"></i>
           </div>
           <div class="my-4">
              {{ $t('With these easy steps, you can have the best souvenirs from Tokyo without the hassle of searching and shopping. ') }}
           </div>
     </div>
  </div>

</template>

<script>
 import video0 from "@/assets/videos/yes_guide/NarabuQ_0.mp4";
 import video1 from "@/assets/videos/no_guide/NarabuQ_1.mp4";
 import video2 from "@/assets/videos/no_guide/NarabuQ_2.mp4";
 import video3 from "@/assets/videos/no_guide/NarabuQ_3.mp4";
 import video4 from "@/assets/videos/no_guide/NarabuQ_4.mp4";
 import video5 from "@/assets/videos/no_guide/NarabuQ_5.mp4";
 import video6 from "@/assets/videos/no_guide/NarabuQ_6.mp4";
 import video7 from "@/assets/videos/no_guide/NarabuQ_7.mp4";
 import video8 from "@/assets/videos/no_guide/NarabuQ_8.mp4";

export default {
  data() {
    return {
      steps: [
      {
          title_en: "Sign Up/Register",
          title_cn: "注册",
          title_jp: "登録",
          description_en:
          "Visit our website and create an account with your personal information.",
          description_cn:
          "访问我们的网站，并使用您的个人信息创建一个帐户。",
          description_jp:
          "当社のウェブサイトを訪問して、個人情報を入力してアカウントを作成してください。",
          video: video0,
        },
        {
          title_en: "Fill Out the Preferences Survey on the Selection Area",
          title_cn: "填写偏好调查",
          title_jp: "ステップ２:ご希望のエリアでお土産の詳細を記入",
          description_en:
          "Complete a quick survey to let us know more about your souvenir preferences. This might include information about your budget, the interests of the person the souvenir is for, and any specific types of souvenirs you are interested in.",
          description_cn:
          "完成一份快速调查，让我们更了解您的纪念品偏好。这可能包括有关您的预算，纪念品面向的人的兴趣以及您感兴趣的任何特定类型的纪念品的信息。",
          description_jp:
          "予算、お土産を渡す人、お土産の種類についての簡単なアンケートをご記入してください。",
          video: video1,
        },
        {
          title_en: "Receive Personalized Souvenir Suggestions",
          title_cn: "收到个性化纪念品建议",
          title_jp: "お土産のリスト化",
          description_en:
          "Based on your survey responses, Qer will curate a list of recommended souvenirs that you might like. This list will be sent to you via website’s chatroom.",
          description_cn:
          "根据您的调查回答，Qer将策划一份您可能喜欢的推荐纪念品清单。该清单将通过网站的聊天室发送给您。",
          description_jp:
          "アンケートの回答に基づいて、弊社がおすすめのお土産リストを作成いたします。このリストは、当社のウェブサイトのチャットルームを介して送信されます。",
          video: video2,
        },
        {
          title_en: "Select Your Favorites",
          title_cn: "选择你的最爱",
          title_jp: "お気に入りを選択する",
          description_en:
          "Review the list of suggested souvenirs, and select the ones you want to purchase. You can also add comments or specific requests for each selected item.",
          description_cn:
          "查看建议的纪念品列表，并选择要购买的纪念品。您还可以为每个选择的项目添加评论或特定请求。",
          description_jp:
          "提案されたお土産のリストを確認し、購入したいものを選択してください。また、選択されたお土産について追加で何かご要望がありましたら、お伝えください。",
          video: video3,
        },
        {
          title_en: "Confirm and Pay",
          title_cn: "确认并支付",
          title_jp: "支払い",
          description_en:
          "Once you've made your selection, confirm the order and proceed to payment. You can pay securely for the souvenirs and our service using various methods, such as credit card or cash.",
          description_cn:
          "选择完毕后，确认订单并继续付款。您可以使用信用卡或现金等各种方式安全地支付纪念品和我们的服务。",
          description_jp:
          "選択が完了したら、注文を確認して支払いを行ってください。クレジットまたは現金をご利用いただけます。支払い方法を選択し、次のステップへ進んでください。 ",
          video: video4,
        },
        {
          title_en: "Receive Order Confirmation",
          title_cn: "收到订单确认",
          title_jp: "注文確認を受け取る",
          description_en:
          "After the payment is processed, you will receive a confirmation email or notification with your order details and an estimated delivery time for your souvenirs.",
          description_cn:
          "付款处理后，您将收到一封确认电子邮件或通知，其中包含您的订单详细信息和纪念品的预计交货时间。",
          description_jp:
          "支払いが処理された後、注文の詳細とお土産のお届け予定日が記載された確認メールまたは通知が届きます。",
          video: video5,
        },
        {
          title_en: "We Purchase Your Souvenirs",
          title_cn: "我们购买您的纪念品",
          title_jp: "弊社がお土産を購入",
          description_en:
          "Qer will then purchase the souvenirs you've selected from trusted local stores in Tokyo. We ensure that all items meet our high-quality standards.",
          description_cn:
          "然后，Qer将从东京值得信赖的当地商店购买您选择的纪念品。我们确保所有物品符合我们的高质量标准。",
          description_jp:
          "弊社はお客様が選択した、お土産を購入します。私たちはすべての商品が高い品質基準を満たしていることを保証します。",
          video: video6,
        },
        {
          title_en: "Delivery or Pickup",
          title_cn: "送货或取货",
          title_jp: "配送・受け取り",
          description_en:
          "Your souvenirs will be packaged securely and can be delivered directly to your hotel or a designated pickup location.",
          description_cn:
          "您的纪念品将被安全地打包，并可以直接送到您的酒店或指定的取货位置。",
          description_jp:
          "お土産を安全に、指定された受け取り場所に配送いたします。",
          video: video7,
        },
        {
          title_en: "Review Our Service",
          title_cn: "评价我们的服务",
          title_jp: "当社のサービスをレビューする",
          description_en:
          "Once your souvenirs have been delivered or picked up, we would love to hear your feedback on our service. We strive to continually improve and your input can help us do so. You can leave a review directly on our website where you can rate your experience with our service and provide any suggestions or comments. Your reviews not only help us improve but also assist other tourists in making an informed decision about using our services. We highly appreciate your time in providing us with your valuable feedback.",
          description_cn:
          "在您的纪念品交付或提取后，我们希望听取您对我们的服务的反馈。我们努力不断改进，您的反馈可以帮助我们实现这一目标。您可以在我们的网站上直接留下评论，评价您使用我们服务的体验并提供任何建议或评论。您的评论不仅可以帮助我们改进，还可以帮助其他游客做出使用我们服务的明智决定。我们非常感谢您提供宝贵的反馈。",
          description_jp:
          "ご注文の品を受け取りましたら、弊社のウェブサイトを通してフィードバックをお聞かせいただければ幸いです。今回のサービスに関しての評価、具体的な改善案などがございましたら、記入してください。皆様のご意見は、私たちのさらなるサービス向上のために大いに役立ちます。",
          video: video8,
        },
      ]


    };
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './guide.styles.scss';
</style>