<template>
    <footer class="footer-section">
        <div class="container">
           <div>{{ $t('Copyright') }} {{ (new Date()).getFullYear() }} @ {{ $t('NarabuQ') }} </div>
           <div class="icon">
            <a href="https://twitter.com/narabuq?s=11&t=c06Iy9T2f-unxRm9-b1DXQ" target=”_blank” ref="twitterRef"><i class="fa-brands fa-twitter"></i></a>
            <a href="https://www.instagram.com/narabu_q" target=”_blank” ref="igRef"><i class="fa-brands fa-instagram"></i></a>
            <a href="https://www.facebook.com/profile.php?id=100092306984158&mibextid=LQQJ4d" target=”_blank” ref="fbRef"><i class="fa-brands fa-facebook-f"></i></a>
            <a href="https://www.xiaohongshu.com/user/profile/62ee31ed000000001e01d947?xhsshare=CopyLink&appuid=62ee31ed000000001e01d947&apptime=1683038351" ref="xhsRef"><img src="@/assets/images/icons/xhs-outlined-white.png" alt="xhs" width="15" height="15"></a>
           </div>
        </div>
    </footer>
</template>

<script>

export default {
    name: 'Footer',
    props: {},
    components: {},
    methods: {
    },
    mounted() { 
        const twitterTip = this.$refs.twitterRef;
        new bootstrap.Tooltip(twitterTip, {
            title: "Twitter",
            placement: "top",
        });

        const igTip = this.$refs.igRef;
        new bootstrap.Tooltip(igTip, {
            title: "Instagram",
            placement: "top",
        });
        
        const fbTip = this.$refs.fbRef;
        new bootstrap.Tooltip(fbTip, {
            title: "Facebook",
            placement: "top",
        });
        
        const xhsTip = this.$refs.xhsRef;
        new bootstrap.Tooltip(xhsTip, {
            title: "小红书",
            placement: "top",
        });
    },
}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './footer.styles.scss';
</style>