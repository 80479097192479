<template>

    <div class="body">
        <form @submit.prevent="verify">
            <div class="container">
                <div class="title mb-4">{{ $t('Booking') }}</div>

                <div class="form-container">
                    <div class="col-12 form-item mb-0">
                        <label>{{ $t('I know what to buy') }}</label>
                        <div class="row">
                            <div class="col-md-6 form-item">
                                <input type="radio" name="suggestion" id="know_what_to_buy_yes" value="0" hidden v-model="suggestion" checked>
                                <label for="know_what_to_buy_yes" class="custom-radio">{{ $t('Yes') }}</label>
                            </div>
                            <div class="col-md-6 form-item">
                                <input type="radio" name="suggestion" id="know_what_to_buy_no" hidden value="1" v-model="suggestion">
                                <label for="know_what_to_buy_no" class="custom-radio">{{ $t('No') }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="my-4"><b>{{ $t('Things to buy') }}:</b></div> 
                    <div v-if="suggestion === '0'">                        
                        <div class="row" v-for="(item, index) in items" :key="index">
                            <div class="col-md-6 form-item">
                                <label :for="'name' + index">{{ $t('Item Name') }}</label>
                                <input :id="'name' + index" type="text" v-model="item.name">
                            </div>
                            <div class="col-md-6 form-item">
                                <label :for="'price' + index">{{ $t('Price') }}</label>
                                <input :id="'price' + index" type="number" v-model.number="item.price">
                            </div>
                            <div class="col-md-6 form-item">
                                <div class="remark-container">
                                    <label :for="'remark' + index">{{ $t('Remark') }}</label>
                                    <div class="remark-icon" @mouseover="isRemarkHover = true" @mouseleave="isRemarkHover = false"><i class="fas fa-question-circle"></i></div>
                                    <div class="tippy-container" v-if="isRemarkHover">
                                        <div class="tippy-content">
                                            <div>{{ $t('Remark') }} :</div>
                                            <div>
                                                <div class="li-dot">{{ $t('Quantity') }}</div>
                                                <div class="li-dot">{{ $t('Colour') }}</div>
                                                <div class="li-dot">{{ $t('Size') }}</div>
                                                <div class="li-dot">{{ $t('Packaging') }}</div>
                                                <div class="li-dot">{{ $t('Flavour') }}</div>
                                            </div>
                                            <div class="quantity">{{ $t('1 item, 2 item') }}</div>
                                            <div class="colour">{{ $t('Red, blue, green') }}</div>
                                            <div class="size">{{ $t('Small, large') }}</div>
                                            <div class="packaging">{{ $t('Plastic bag needed') }}</div>
                                            <div class="flavour">{{ $t('Strawberry, vanila') }}</div>
                                            <div>
                                                <p>{{ $t('Example') }}</p>
                                                <p>{{ $t('1x Charizard keychain') }}</p>
                                                <p>{{ $t('2x L size blue naruto t-shirt') }}</p>
                                            </div>
                                        </div>
                                    </div>
                 
                                </div>
                                <input :id="'remark' + index" type="text" v-model="item.remark">
                            </div>
                            <div class="col-md-12 d-flex flex-column">
                                <label class="mb-4" :for="'image' + index">{{ $t('Image') }}</label>
                                <input :id="'image' + index" type="file" @change="handleImageChange($event, index)">
                            </div>
                            <div class="col-6 col-md-3 my-4">
                                <Button type="button"  @click="addItem" :title="$t('Add Item')" ButtonStyle="full-fill" />
                            </div>
                            <div class="col-6 col-md-3 my-4">
                                <Button type="button"  @click="removeItem(index)" v-if="items.length > 1" :title="$t('Remove Item')" ButtonStyle="full-fill" />
                            </div>
                            <hr class="my-5">
                        </div>                        
                    </div>
                    <div class="row">
                        <div class="col-md-6 form-item" v-if="suggestion === '1'">
                            <label>{{ $t('Budget') }}</label>
                            <select name="budget" id="budget" v-model="budget">
                                <option value="5,000-10,000">{{ $t('5,000-10,000 yen') }}</option>
                                <option value="10,000-20,000">{{ $t('10,000-20,000 yen') }}</option>
                                <option value=">20,000">{{ $t('More than 20,000 yen') }}</option>
                            </select>   
                        </div>               
                        <div id="item-catgory-container" class="col-md-6 form-item" v-if="suggestion === '1'">
                            <label>{{ $t('Item Category') }}</label>
                            <div id="category-text" class="item-category" @click="toggleCategory"></div>
                            <CustomModal :isToggle="isCategoryToggle" @toggleModal="setCategoryToFalse">
                                <div class="container category-container pb-4">
                                    <div class="title text-center mb-8 mt-4">{{ $t('Item Category') }}</div>
                                    <div class="row">
                                        <div class="col-6 col-md-4 category-col">
                                            <input type="checkbox"  name="item_category" id="anime_manga" value="anime_manga" hidden v-model="categories">
                                            <label for="anime_manga">{{ $t('Anime/Manga') }}</label>
                                        </div>
                                        <div class="col-6 col-md-4 category-col">
                                            <input type="checkbox"  name="item_category" id="traditional_japanese_art_and_culture" value="traditional_japanese_art_and_culture" hidden v-model="categories">
                                            <label for="traditional_japanese_art_and_culture">{{ $t('Traditional Japanese Art and Culture') }}</label>
                                        </div>
                                        <div class="col-6 col-md-4 category-col">
                                            <input type="checkbox"  name="item_category" id="food_and_drink" value="food_and_drink" hidden v-model="categories">
                                            <label for="food_and_drink">{{ $t('Food and Drink') }}</label>
                                        </div>
                                        <div class="col-6 col-md-4 category-col">
                                            <input type="checkbox"  name="item_category" id="medicine_supplements" value="medicine_supplements" hidden v-model="categories">
                                            <label for="medicine_supplements">{{ $t('Medicine/Supplements') }}</label>
                                        </div>
                                        <div class="col-6 col-md-4 category-col">
                                            <input type="checkbox"  name="item_category" id="fashion_accessiories" value="fashion_accessiories" hidden v-model="categories">
                                            <label for="fashion_accessiories">{{ $t('Fashion/Accessories') }}</label>
                                        </div>
                                        <div class="col-6 col-md-4 category-col">
                                            <input type="checkbox"  name="item_category" id="technology_gadgets" value="technology_gadgets" hidden v-model="categories">
                                            <label for="technology_gadgets">{{ $t('Technology/Gadgets') }}</label>
                                        </div>
                                        <div class="col-6 col-md-4 category-col">
                                            <input type="checkbox"  name="item_category" id="nature_outdoors" value="nature_outdoors" hidden v-model="categories">
                                            <label for="nature_outdoors">{{ $t('Nature/Outdoors') }}</label>
                                        </div>
                                    </div>
                                </div>
                            </CustomModal>
                        </div>
                    </div>
                    <div class="my-4"><b>{{ $t('Shipping Details') }}</b></div>
                    <div class="row">
                       
                        <div class="col-md-6 form-item">
                            <label for="date">{{ $t('Date') }}</label>
                            <input type="date" id="date" name="date" v-model="date"  :min="minDate">
                        </div>
                        <div class="col-md-6 form-item">
                            <label for="time">{{ $t('Time') }}</label>
                            <select id="time" name="time" v-model="time">
                                <option v-for="option in timeOptions" :value="option.value" :key="option.value">
                                    {{ option.label }}
                                </option>
                            </select>
                        </div>
                     
                        <div class="col-md-12 form-item">
                            <label for="time">{{ $t('Contact Number') }}</label>
                            <input type="text" id="phone" name="phone" v-model="phone">
                        </div>
                        
                        <div class="my-4"><b>{{ $t('Shipping Method') }}</b></div>
                        <div class="col-12 form-item mb-0">
                            <div class="row">
                                <div class="col-md-6 form-item">
                                    <input type="radio" name="shipping_method" id="face_to_face" value="face_to_face" hidden v-model="shipping_method">
                                    <label for="face_to_face" class="custom-radio">{{ $t('Face to Face') }}</label>
                                </div>
                                <div class="col-md-6 form-item">
                                    <input type="radio" name="shipping_method" id="postage" value="postage" hidden v-model="shipping_method">
                                    <label for="postage" class="custom-radio">{{ $t('Postage') }}</label>
                                </div>
                            </div>
                        </div>          
                        <div class="col-12 form-item mb-0">
                            <label class="mb-2">{{ $t('Address') }}</label>
                            <div class="row">
                                <div class="col-md-6 form-item">
                                    <label for="zipcode">{{ $t('Zipcode') }}:</label>
                                    <input type="text" id="zipcode" name="zipcode" v-model="zipcode">
                                </div>
                                <div class="col-md-6 d-none d-md-flex"></div>
                                <div class="col-md-6 form-item">
                                    <label for="country">{{ $t('Country') }}:</label>
                                    <select name="country" id="country" v-model="country">
                                   
                                        <option value="Japan">{{ $t('Japan') }}</option>
                                    </select>
                                </div>      
                                <div class="col-md-6 d-none d-md-flex"></div>
                                <div class="col-md-6 form-item">
                                    <label for="state">{{ $t('State') }}:</label>
                                    <select name="state" id="state" v-model="state">
                                        <option value="愛知県">{{ $t('Aichi Prefecture') }}</option>
                                        <option value="秋田県">{{ $t('Akita Prefecture') }}</option>
                                        <option value="青森県">{{ $t('Aomori Prefecture') }}</option>
                                        <option value="千葉県">{{ $t('Chiba Prefecture') }}</option>
                                        <option value="愛媛県">{{ $t('Ehime Prefecture') }}</option>
                                        <option value="福井県">{{ $t('Fukui Prefecture') }}</option>
                                        <option value="福岡県">{{ $t('Fukuoka Prefecture') }}</option>
                                        <option value="福島県">{{ $t('Fukushima Prefecture') }}</option>
                                        <option value="岐阜県">{{ $t('Gifu Prefecture') }}</option>
                                        <option value="群馬県">{{ $t('Gunma Prefecture') }}</option>
                                        <option value="広島県">{{ $t('Hiroshima Prefecture') }}</option>
                                        <option value="北海道">{{ $t('Hokkaido Prefecture') }}</option>
                                        <option value="兵庫県">{{ $t('Hyogo Prefecture') }}</option>
                                        <option value="茨城県">{{ $t('Ibaraki Prefecture') }}</option>
                                        <option value="石川県">{{ $t('Ishikawa Prefecture') }}</option>
                                        <option value="岩手県">{{ $t('Iwate Prefecture') }}</option>
                                        <option value="香川県">{{ $t('Kagawa Prefecture') }}</option>
                                        <option value="鹿児島県">{{ $t('Kagoshima Prefecture') }}</option>
                                        <option value="神奈川県">{{ $t('Kanagawa Prefecture') }}</option>
                                        <option value="高知県">{{ $t('Kochi Prefecture') }}</option>
                                        <option value="熊本県">{{ $t('Kumamoto Prefecture') }}</option>
                                        <option value="京都府">{{ $t('Kyoto Prefecture') }}</option>
                                        <option value="三重県">{{ $t('Mie Prefecture') }}</option>
                                        <option value="宮城県">{{ $t('Miyagi Prefecture') }}</option>
                                        <option value="宮崎県">{{ $t('Miyazaki Prefecture') }}</option>
                                        <option value="長野県">{{ $t('Nagano Prefecture') }}</option>
                                        <option value="長崎県">{{ $t('Nagasaki Prefecture') }}</option>
                                        <option value="奈良県">{{ $t('Nara Prefecture') }}</option>
                                        <option value="新潟県">{{ $t('Niigata Prefecture') }}</option>
                                        <option value="大分県">{{ $t('Oita Prefecture') }}</option>
                                        <option value="岡山県">{{ $t('Okayama Prefecture') }}</option>
                                        <option value="沖縄県">{{ $t('Okinawa Prefecture') }}</option>
                                        <option value="大阪府">{{ $t('Osaka Prefecture') }}</option>
                                        <option value="佐賀県">{{ $t('Saga Prefecture') }}</option>
                                        <option value="埼玉県">{{ $t('Saitama Prefecture') }}</option>
                                        <option value="滋賀県">{{ $t('Shiga Prefecture') }}</option>
                                        <option value="島根県">{{ $t('Shimane Prefecture') }}</option>
                                        <option value="静岡県">{{ $t('Shizuoka Prefecture') }}</option>
                                        <option value="栃木県">{{ $t('Tochigi Prefecture') }}</option>
                                        <option value="徳島県">{{ $t('Tokushima Prefecture') }}</option>
                                        <option value="東京都">{{ $t('Tokyo Metropolis') }}</option>
                                        <option value="鳥取県">{{ $t('Tottori Prefecture') }}</option>
                                        <option value="富山県">{{ $t('Toyama Prefecture') }}</option>
                                        <option value="和歌山県">{{ $t('Wakayama Prefecture') }}</option>
                                        <option value="山形県">{{ $t('Yamagata Prefecture') }}</option>
                                        <option value="山口県">{{ $t('Yamaguchi Prefecture')}}</option>
                                        <option value="山梨県">{{ $t('Yamanashi Prefecture') }}</option>

                                    </select>
                                </div>
                                <div class="col-md-6 d-none d-md-flex"></div>
                                <div class="col-md-6 form-item">
                                    <label for="city">{{ $t('City') }}:</label>
                                    <input type="text" id="city" name="city" v-model="city">
                                </div>
                                <div class="col-md-6 d-none d-md-flex"></div>
                                <div class="col-md-6 form-item">
                                    <label for="street">{{ $t('Street') }}:</label>
                                    <input type="text" id="street" name="street" v-model="street">
                                </div>
                                <div class="col-md-6 d-none d-md-flex"></div>
                                <div class="col-md-6 form-item">
                                    <label for="building">{{ $t('Building') }}:</label>
                                    <input type="text" id="building" name="building" v-model="building">
                                </div>         
                                <div class="col-md-6 d-none d-md-flex"></div>
                                
                            </div>
                        </div>        
                        <LoadingAnimation :isLoading="isLoading" />
                       
                        <div class="col-12 my-4">
                            <div class="md:w-1/4 me-auto">
                                <Button type="button" :title="$t('Submit')" ButtonStyle="color-fill" @click="verify" />
                            </div>
                        </div>

                    </div>                                    
                </div>
            </div>
        </form>
    </div>

</template>

<script>

import Button from '@/components/button/button.component.vue'
import CustomModal from '@/components/custom-modal/custom-modal.component.vue'
import axios from 'axios';
import LoadingAnimation from '@/components/queue-loading/queue-loading.component.vue';

export default {
    
    components: {
        Button,        
        CustomModal,  
        LoadingAnimation,
    },
    data() {
        return {
            date:"",
            time:"",
            timeOptions: [], // Available time options
            budget: "5000-1000",
            url:"",
            phone:"",
            suggestion: "0",
            street: '',
            city: '',
            state: '',
            zipcode: '',
            building: '',
            country: 'Japan',
            shipping_method: '',
            categories: [],
            items:[
                {
                    name: '',
                    remark: '',
                    price: '',
                    image: null,
                },
            ],
            isCategoryToggle: false,
            isRemarkHover: false,
            isLoading: false,
        };
    },
    methods: {
        handleImageChange(event, index) {
            this.items[index].image = event.target.files[0];
        },
        addItem() {
            this.items.push({
                name: '',
                price: '',
                image: null,
                remark: ''
            });
        },
        removeItem(index) {
            this.items.splice(index, 1);
        },
      
        make_booking(){
            this.isLoading = true;
            this.url = this.HostUrl + "booking/create-bookings"
            const formData = new FormData();
            formData.append('suggestion', this.suggestion)
            formData.append('date', this.date);
            formData.append('time', this.time);
            formData.append('phone', this.phone);
            formData.append('budget',this.budget);
            formData.append('shipping_method', this.shipping_method);
            formData.append('street', this.street);
            formData.append('city', this.city);
            formData.append('state', this.state);
            formData.append('zipcode', this.zipcode);
            formData.append('building', this.building);
            formData.append('country', this.country);
            formData.append('username', this.Username);
            formData.append('user_id', this.UserID);
            console.log( this.categories.join(';'))
            formData.append('categories', this.categories.join(';'));
            //formData.append('items',this.items);
            console.log(this.items);
            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];
                formData.append(`items[${i}][name]`, item.name);
                formData.append(`items[${i}][price]`, item.price);
                formData.append(`items[${i}][image]`, item.image);
                formData.append(`items[${i}][remark]`, item.remark);
                
            }
            console.log(formData)
            axios.post(this.url,formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log(response)
                if (response.data['status'] == 200) {
                    alert(this.$t('Booking complete! Qers will contact you soon.'))
                    this.$router.push("/manage-bookings");
                }
                else {
                    alert("Error !");
                }
               
      
                
            })
            .catch(error => {
                console.log(error);
                
                alert("Error")
            }).finally(() => {
                this.isLoading = false; // Hide the loader
            });
        },

        verify() {
            this.feedback = "";

            if (this.date === "") {
                this.feedback = this.$t("You must enter a date");
            }

            if (this.time === "") {
                this.feedback = this.$t("You must enter a time");
            }

            // Check the street field
            if (this.street === "") {
                this.feedback = this.$t("You must enter a street");
            }

            // Check the city field
            if (this.city === "") {
                this.feedback = this.$t("You must enter a city");
            }

            // Check the state field
            if (this.state === "") {
                this.feedback = this.$t("You must enter a state");
            }

            // Check the zipcode field
            if (this.zipcode === "") {
                this.feedback = this.$t("You must enter a zipcode");
            }

            if (parseInt(this.suggestion) === 1) {
                if (this.categories.length < 1) {
                this.feedback = this.$t("You must choose at least a category");
                }
                if (this.budget === "") {
                this.feedback = this.$t("You must choose a budget");
                }
            } else {
                for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];

                // Check the name field
                if (item.name === "") {
                    this.feedback = this.$t("You must enter a name for item");
                    break;
                }

                // Check the description field
                if (item.remark === "") {
                    this.feedback = this.$t("You must enter a description for item");
                    break;
                }

                // Check the price field
                if (item.price === "") {
                    this.feedback = this.$t("You must enter a price for item");
                    break;
                }
                }
            }

            if (this.shipping_method === "") {
                this.feedback = this.$t("You must choose a shipping method");
            }

            if (this.feedback !== "") {
                alert(this.feedback)
                console.log(this.isLoading);
            } else {
                this.make_booking();
            }
        },
        toggleCategory() {
            this.isCategoryToggle = true;
            document.body.style.overflow = "hidden";
        },
        setCategoryToFalse() {
            this.isCategoryToggle = false;
            this.setupItemCategory()
        },
        setupItemCategory() {

            this.categoryItemList = [];
            this.categoryText = document.querySelector("#category-text");

            this.categoryLabels = document.querySelectorAll(".category-col label");
            this.categoryInputs = document.querySelectorAll(".category-col input");

            this.categoryInputs.forEach((input, num) => {
          
            this.categoryItemList = Array.from(this.categoryInputs)
                .filter((input) => input.checked)
                .map((input) => {
                const label = this.categoryLabels
                    [
                        Array.from(this.categoryInputs).indexOf(input)
                    ].innerHTML;
                    return label.trim();
                });
                
                this.categoryText.innerHTML = this.categoryItemList.join(", ");
               
            });
        
        }
    },    
    mounted(){
      
         // Calculate available time options
        const formatTime = (hours, minutes) => {
            const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');
            const meridiem = hours < 12 ? 'am' : 'pm';
            const formattedTime = `${formattedHours}:${formattedMinutes}`;
            return `${formattedTime} ${meridiem}`;
        };

        const getTimeOptions = () => {
            const options = [];
            for (let hours = 0; hours <= 23; hours++) {
                const formattedTime = formatTime(hours, 0);
                options.push({
                value: `${hours.toString().padStart(2, '0')}:00:00`,
                label: formattedTime
                });
                options.push({
                value: `${hours.toString().padStart(2, '0')}:30:00`,
                label: `${formattedTime.substr(0, 2)}:30 ${hours < 12 ? 'am' : 'pm'}`
                });
            }
            return options;
        };

        this.timeOptions = getTimeOptions();
    },
    
    created() {
    },

    computed: {
        HostUrl() {
            return this.$store.state.host_url;
        },
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        minDate() {
            const today = new Date();
            const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
            return tomorrow.toISOString().split('T')[0];
        },
        
    },
    updated(){
        
    }
};
</script>
<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './booking.styles.scss';
    #remark::placeholder {
        color:lightgrey;
    }
</style>