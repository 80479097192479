<!-- LoadingAnimation.vue -->
<template>
  <div class="loading-container" v-if="isLoading">
    <pulse-loader color="orange" size="15px"></pulse-loader>
  </div>
</template>

<!-- Rest of the component remains the same -->

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  name: 'LoadingAnimation',
  components: {
    PulseLoader,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    }
  },
};
</script>

<style scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>