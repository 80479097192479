export const navLanguage = () => {
    const button = document.querySelector('.icon.language');
    if (!button) throw new Error('Cannot find class "icon language"');

    const closeBox = (e) => {
        if (e) {
            e.stopPropagation();
            if (button.contains(e.target)) return;
            button.classList.remove('active');
            removeEventListener('click', closeBox);
        } else {
            button.classList.remove('active');
            removeEventListener('click', closeBox);
        }
    }

    button.addEventListener('click', (e)=>{
        e.stopPropagation();        
        if (button.classList.contains('active')) return closeBox();
        button.classList.add('active');
        addEventListener('click', closeBox);
    })
}

export const navMobileMenu = () => {
    const body = document.querySelector('body');

    const navbar = document.querySelector('.navbar-section');
    if (!navbar) throw new Error('Cannot find class "navbar-section"');
    
    const iconContainer = navbar.querySelector('.icon-container');
    if (!iconContainer) throw new Error('Cannot find class "icon-container"');

    const menuButton = navbar.querySelector('.menu-icon');
    if (!menuButton) throw new Error('Cannot find class "menu-icon"');
    
    const closeIcon = navbar.querySelector('.close-icon');
    if (!closeIcon) throw new Error('Cannot find class "close-icon"');
    
    const iconContainerCloseButtons = iconContainer.querySelectorAll('.close-button');
    
    const displayText = iconContainer.querySelector('.display-text');
    if (!displayText) throw new Error('Cannot find class "display-text"');
    
    const mobileContainerButton = navbar.querySelector('.icon.language.mobile');
    if (!mobileContainerButton) throw new Error('Cannot find class "icon language mobile"');
    
    const mobileContainer = navbar.querySelector('.mobile-language-container');
    if (!mobileContainer) throw new Error('Cannot find class "mobile-language-container"');
    
    const mobileDropdown = navbar.querySelector('.dropdown.mobile');
    if (!mobileDropdown) throw new Error('Cannot find class "dropdown mobile"');
    
    const mobileContainerCloseButtons = mobileContainer.querySelectorAll('.close-button');
    
    
    // click menu icon to toggle menu in mobile
    menuButton.addEventListener('click', ()=>{
        if (navbar.classList.contains('active')) return navbar.classList.remove('active');
        navbar.classList.add('active');
    })

    // setup close function
    closeIcon.addEventListener('click', ()=>{
        if (!navbar.classList.contains('active')) return;
        navbar.classList.remove('active');
    })

    if (iconContainerCloseButtons.length) {
        iconContainerCloseButtons.forEach(closeButton => {
            closeButton.addEventListener('click', ()=>{
                if (!navbar.classList.contains('active')) return;
                navbar.classList.remove('active');
            })
        });
    }

    // setup mobile language modal
    mobileContainerButton.addEventListener('click',()=>{
        if (mobileContainer.classList.contains('active')) return;
        mobileContainer.classList.add('active');
        body.setAttribute('style','overflow: hidden');
    })

    mobileContainerCloseButtons.forEach(button => {
        button.addEventListener('click',()=>{
            mobileContainer.classList.remove('active');
            body.removeAttribute('style');
            displayText.innerHTML = button.innerHTML;
        })        
    });

    mobileContainer.addEventListener('click',(e)=>{
        if (mobileDropdown.classList.contains(e.target)) return;
        mobileContainer.classList.remove('active');
        body.removeAttribute('style');
    })
}