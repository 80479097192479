<template>  
    <div class="small-card-container">
        <slot></slot>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeSmallCard',
  components: {
  },
  data() {
    return{
    }
  }
}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './home-small-card.styles.scss';
</style>