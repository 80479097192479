<template>
   <div class="body">
      <div class="container">
         <div class="title">{{ $t('Step-by-Step Guide to Place an Order for Proxy Queuing for Souvenirs') }}</div>
         <div class="mb-8">
            {{ $t('Placing an order for our proxy queuing service to buy souvenirs is a breeze with NarabuQ.') }}{{ $t('Follow these easy steps to have our team save your time and effort while shopping for the perfect souvenirs:') }}
         </div>
         <div class="row">
            <div class="col-12 guide-col" v-for="(step, index) in steps" :key="index">
               <div class="image-container">
                  <video autoplay loop muted>               
                     <source :src="step.video" type="video/mp4">
                        Your browser does not support the video tag.
                  </video>                  
               </div>
               <div class="content-container">
                  <div class="step-container">
                     <div class="step-title">
                        <strong>{{ $t('Step') }} {{ index + 1 }}:</strong> 
                        <span v-if="$i18n.locale=='en'">{{ step.title_en }}</span>
                        <span v-if="$i18n.locale=='cn'">{{ step.title_cn }}</span>
                        <span v-if="$i18n.locale=='jp'">{{ step.title_jp }}</span>
                     </div>
                     <div class="step-description">
      
                        <span v-if="$i18n.locale=='en'">{{ step.description_en }}</span>
                        <span v-if="$i18n.locale=='cn'">{{ step.description_cn }}</span>
                        <span v-if="$i18n.locale=='jp'">{{ step.description_jp }}</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div v-if="index < steps.length - 1" class="arrow-container text-center">
            <i class="bi bi-arrow-down"></i>
         </div>
         <div class="my-4">
            {{ $t('By following these simple steps, you can conveniently place an order for our proxy queuing service for souvenir shopping. ') }}
         </div>
      </div>
   </div>
 
 </template>
 
 <script>
 import video0 from "@/assets/videos/yes_guide/NarabuQ_0.mp4";
 import video1 from "@/assets/videos/yes_guide/NarabuQ.mp4";
 import video2 from "@/assets/videos/yes_guide/NarabuQ_1.mp4";
 import video3 from "@/assets/videos/yes_guide/NarabuQ_2.mp4";
 import video4 from "@/assets/videos/yes_guide/NarabuQ_3.mp4";
 import video5 from "@/assets/videos/yes_guide/NarabuQ_4.mp4";
 import video6 from "@/assets/videos/yes_guide/NarabuQ_5.mp4";
 
 export default {
   data() {
     return {
      steps: [
         {
            title_en: "Sign Up/Register",
            title_cn: "注册",
            title_jp: "登録",
            description_en:
               "Visit our website and create an account with your personal information. This will help us tailor our services to meet your needs more effectively.",
            description_cn: "并使用您的个人信息创建一个帐户。这将帮助我们更有效地量身定制我们的服务以满足您的需求。",
            description_jp: "当社のウェブサイトを訪問して、個人情報を入力してアカウントを作成してください。これにより、より効果的にサービスを調整し、お客様のニーズに合わせることができます。",
            video: video0,
         },
         {
            title_en: "Provide Details",
            title_cn: "提供详细信息",
            title_jp: "詳細を記入する",
            description_en:
            "Provide specific information about your souvenir preferences, such as quantity, color, size, or any customization requests. This will help our team efficiently purchase the desired items on your behalf. Besides that, you also need to provide the date, time, and address which you want this item to be sent to. Based on your preference, we can either deliver the purchased souvenirs directly to your hotel or a designated pickup location, or you can collect them from our proxy queuer at the store.",
            description_cn: "提供有关您的纪念品喜好的具体信息，例如数量，颜色，大小或任何定制请求。这将有助于我们的团队代表您高效地购买所需的物品。除此之外，您还需要提供想要发送该物品的日期，时间和地址。根据您的喜好，我们可以将购买的纪念品直接送到您的酒店或指定的取货地点，或者您可以从我们的代理排队者那里收取它们。",
            description_jp: "数量、色、サイズ、カスタマイズ要求など、あなたのお土産の好みに関する具体的な情報を提供してください。これにより、弊社が代わりにご要望のアイテムを効率的に購入できます。そして、お土産を配送する日時と住所を指定してください。お好みに応じて、購入したお土産をホテルや指定のピックアップ場所に直接配送することもできます。また、弊社の代理人から受け取ることもできます。",
            video: video1,
         },
         {
            title_en: "Wait Qer to accept or counter offer your request",
            title_cn: "等待Qer接受或反向报价",
            title_jp: "Qerがあなたのリクエストを受け入れるか、カウンターオファーをするのを待つ",
            description_en:
            "Qer will contact you personally through website’s chatroom to make a confirm about your order. If there’s no counter offer, you will be directed to the payment’s page. Whereas, if there’s counter offer and you need to negotiate with the Qer to get the best price as you wished.",
            description_cn: "Qer将通过网站的聊天室与您联系，以确认您的订单。如果没有反向报价，您将被引导到付款页面。而如果有反向报价，您需要与Qer协商以获得您想要的最佳价格。",
            description_jp: "Qerは、お客様のご注文の確認のために、ウェブサイトのチャットルームを通じてご直接連絡をいたします。ご注文に間違いがない場合、支払いページへ移動します。ただし、ご注文の修正や価格交渉が必要な場合は、お申しつけください。",
            video: video2,
         },
         {
            title_en: "Confirm and Pay",
            title_cn: "确认并支付",
            title_jp: "支払い",
            description_en:
            "Review your order, and confirm the details. Proceed to the payment section, where you can securely pay for the souvenirs and our proxy queuing service using various methods, such as credit card or cash.",
            description_cn: "审核您的订单并确认详细信息。进入付款部分，您可以使用各种方法，如信用卡或现金，安全地支付纪念品和我们的代理排队服务。",
            description_jp: "ご注文内容を確認してください。クレジットまたは現金をご利用いただけます。支払い方法を選択し、次のステップへ進んでください。 ",
            video: video3,
         },
         {
            title_en: "Receive Confirmation",
            title_cn: "收到确认",
            title_jp: "注文の確認を受け取る",
            description_en:
            "Once your payment is processed, you will receive a confirmation email or notification with your order details.",
            description_cn: "一旦处理您的付款，您将收到一封确认电子邮件或通知，其中包含您的订单详细信息。",
            description_jp: "支払いが処理されると、注文の詳細を含む確認メールまたは通知が届きます。",
            video: video4,
         },
         {
            title_en: "Proxy Queuer Purchases Souvenirs",
            title_cn: "代理排队者购买纪念品",
            title_jp: "お土産の受け渡し",
            description_en:
            "Our designated proxy queuer will wait in line and purchase your selected souvenirs according to your provided specifications and send it to your designated pickup location.",
            description_cn: "我们指定的代理排队者将排队等待，并根据您提供的规格购买您选择的纪念品，并将其送到您指定的取货地点。",
            description_jp: "弊社の代理人が、ご注文の品を購入し、指定された場所へ届けます。",
            video: video5,
         },
         {
            title_en: "Review Our Service",
            title_cn: "评论我们的服务",
            title_jp: "レビュー",
            description_en:
            "Once your souvenirs have been delivered or picked up, we would love to hear your feedback on our service. We strive to continually improve and your input can help us do so. You can leave a review directly on our website where you can rate your experience with our service and provide any suggestions or comments. Your reviews not only help us improve but also assist other tourists in making an informed decision about using our services. We highly appreciate your time in providing us with your valuable feedback.",
            description_cn: "一旦您的纪念品已经交付或取货，我们很乐意听取您对我们的服务的反馈。我们致力于不断改进，您的意见可以帮助我们做到这一点。您可以直接在我们的网站上留下评论，评价您使用我们服务的经验并提供任何建议或意见。您的评论不仅有助于我们改进，而且还可以帮助其他旅游者做出明智的决定，使用我们的服务。我们非常感谢您花费宝贵的时间为我们提供有价值的反馈。",
            description_jp: "ご注文の品を受け取りましたら、弊社のウェブサイトを通してフィードバックをお聞かせいただければ幸いです。今回のサービスに関しての評価、具体的な改善案などがございましたら、記入してください。皆様のご意見は、私たちのさらなるサービス向上のために大いに役立ちます。",
            video: video6,
         },
      ]

     };
   },
 };
 </script>
 
<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './guide.styles.scss';
</style>