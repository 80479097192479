<template>  
    <div class="image-slider-container">
        <Splide :has-track="false" :options="splideOption" aria-label="home-banner">            
            <SplideTrack>
                <SplideSlide v-for="(video, index) in videos" :key="index">
                        <video autoplay loop muted>
                 
                          <source :src="video.video" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                      </SplideSlide>
            </SplideTrack>

            <div class="splide__arrows">
                <button class="splide__arrow splide__arrow--prev"><i class="fa-solid fa-chevron-left"></i></button>
                <button class="splide__arrow splide__arrow--next"><i class="fa-solid fa-chevron-right"></i></button>
            </div>
        </Splide>
    </div>
</template>

<script>
// @ is an alias to /src

import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css';
import '@splidejs/vue-splide/css/core';

import long_video_1 from "@/assets/videos/home_page/NarabuQ-long-1.mp4";
import long_video_2 from "@/assets/videos/home_page/NarabuQ-long-2.mp4";

export default {
    name: 'HomeImageSlider',
    components: {
        Splide, 
        SplideSlide,
        SplideTrack,
    },
    data() {
        return{
            splideOption: {
                rewind: true,
                autoplay: true,
                interval: 5000,
            },
            videos: [
            {
                video: long_video_1,
            },
            {
                video: long_video_2,
            },
        ],
    }
  }
}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './home-image-slider.styles.scss';
</style>