<template>
  <div class="body">
    <div class="container">
      <div class="title mb-4">{{ $t('Select Payment Method') }}</div>

      <div class="method-container">
        <div class="">
          <input type="radio" id="cash" value="cash" v-model="selectedPaymentMethod" hidden>
          <label for="cash">
            <div><i class="fas fa-wallet"></i></div>
            <div>{{ $t('Cash') }}</div>
          </label>
        </div>

        <div class="">
          <input type="radio" id="bank_transfer" value="bank_transfer" v-model="selectedPaymentMethod" hidden>
          <label for="bank_transfer">
            <div><i class="far fa-credit-card"></i></div>
            <div>{{ $t('Bank Transfer') }}</div>
          </label>
        </div>
      </div>

      <div class="bank-receipt" v-if="selectedPaymentMethod === 'bank_transfer'">
        <div>
          <h2 class="title">{{ $t('Upload Bank Transfer Receipt') }}</h2>
          <div class="my-4">MUFG</div>
          <div class="my-4">三菱UFG銀行駒込支店</div>
          <div class="my-4">  普通 0547669</div>
        </div>
        <input type="file" @change="handleFileUpload" accept="image/*">
      </div>

      <Button @click="submitPayment" :title="$t('Pay')" />
      <LoadingAnimation :isLoading="isLoading" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Button from '@/components/button/button.component.vue'
import LoadingAnimation from '@/components/queue-loading/queue-loading.component.vue';
export default {
  components: {
    Button,
    LoadingAnimation  
  },
  data() {
    return {
      selectedPaymentMethod: "",
      receiptFile: null,
      url:"",
      isLoading: false,
    };
  },
  methods: {
    handleFileUpload(event) {
      this.receiptFile = event.target.files[0];
    },
    async submitPayment() {

      if (this.selectedPaymentMethod === "") {
        alert("Please select a payment method.");
        return;
      }

      if (this.selectedPaymentMethod === "bankTransfer" && !this.receiptFile) {
        alert("Please upload a bank transfer receipt.");
        return;
      }
      this.isLoading = true;
      const formData = new FormData();
      formData.append("payment_method", this.selectedPaymentMethod);
      formData.append("booking_id", this.BookingID);

      if (this.receiptFile) {
        formData.append("receiptFile", this.receiptFile);
      }

      // Send the request to your Laravel backend
      this.url = this.HostUrl + "payment"
      try {
        const response = await axios.post(this.url, formData);
        console.log(response.data);
        this.$router.push("/manage-bookings");
      } catch (error) {
        console.error(error);
        alert("An error occurred while submitting the payment.");
      }
      this.isLoading = false;
    },
  },
  computed: {
        HostUrl() {
            return this.$store.state.host_url;
        },
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        PaymentID(){
            return this.$store.state.payment_id;
        },
        BookingID(){
            return this.$store.state.booking_id;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './payment.styles.scss';
</style>